import React from "react";
import Helmet from "react-helmet";
import Layout from "../../layout";
import SEO from "../../blogComponents/SEO/SEO";
import SavedHomesContent from "./SavedHomesContent";
import config from "../../../data/SiteConfig";
import './index.css';


export default class SavedHomesPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={`${config.siteTitle} | Saved Homes`} />
          <SEO />
          <SavedHomesContent />
        </div>
      </Layout>
    );
  }
}
