import React, {useEffect} from "react";
import './index.css';
import './saved-homes-from-ext.css';
import './trash-icon.css';
import VerifiedStabilizedBuilding from '../../../../static/VerifiedStabilizedBuilding.svg';

export default function SavedHomesContent() {
  useEffect(() => {
    // triggers lookup
    window.dispatchEvent(new Event('check-yo-self-28827864295383154'));
  }, []);

  return (
    <div className="saved-homes-content-wrapper">
      <div className="saved-homes-us-floating-cube" />
      <div className="saved-homes-us-floating-circle" />
      <div className="saved-homes-header">
        <img src={VerifiedStabilizedBuilding} alt="stabilized unit" />
        <div className="main-text saved-homes-us-main-text saved-homes-header-text">
          Saved<br />Homes
        </div>
      </div>
      <div className="saved-homes-list-container __x__" id="saved-homes-list-container-41627732169112175" />
    </div>
  );
}
